<template>
  <div class="user">
    <template v-if="!loading">
      <AdminSubMenu v-if="partner" :menu="menu" :sub-menu="subMenu">
        {{ partner.name }}
      </AdminSubMenu>
      <router-view />
    </template>
  </div>
</template>

<i18n>
{
"en": {
"Overview": "Overview",
"PIC": "PIC",
"Bank Account": "Bank Account"
},
"ja": {
"Overview": "概要",
"PIC": "主担当者",
"Bank Account": "銀行口座"
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import previousRoute from '@/components/mixins/previousRoute';
import AdminSubMenu from '@/components/AdminSubMenu';

export default {
  name: 'Partner',
  components: { AdminSubMenu },
  mixins: [previousRoute],
  data() {
    return {
      menuName: 'partners',
    };
  },
  computed: {
    menu() {
      return {
        query: this.previousRouteQuery,
        name: this.menuName,
        title: this.$t('Back'),
      };
    },
    subMenu() {
      return [
        {
          name: 'partner-overview',
          title: this.$t('Overview'),
        },
        {
          name: 'partner-pic',
          title: this.$t('PIC'),
        },
        {
          name: 'partner-bank',
          title: this.$t('Bank Account'),
        },
      ];
    },
    childrenRoutes() {
      return ['partner-overview', 'partner-pic', 'partner-bank'];
    },
    ...mapState('partners', ['partner', 'loading']),
  },
  watch: {
    /* $route(to) {
      if (this.childrenRoutes.indexOf(to.name) !== -1) {
        this.asyncData(to)
      }
    } */
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData(route = null) {
      route = route || this.$route;
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        this.loading = true;
        this.partner = await this.$store.dispatch('partners/get', { id: route.params.id });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
  },
};
</script>

<style scoped></style>
