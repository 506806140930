<template>
  <div class="section">
    <div v-if="partner" class="container">
      <div class="columns is-large-gap">
        <div class="column is-6">
          <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
            {{ $t('Partner Details') }}
          </h2>
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Partner info') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <PartnerForm
                      :value="partner"
                      :property-list="propertyList"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Partner Name') }}
                </div>
                <div>{{ partner.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Postal Code') }}
                </div>
                <div>{{ partner.postalCode }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Country') }}
                </div>
                <div>{{ $filters.countryNameByIso(partner.country) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Perfecture / State') }}
                </div>
                <div>{{ $t(partner.state) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Address') }}
                </div>
                <div>{{ partner.address }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Added at') }}
                </div>
                <div>{{ $filters.date(partner.createdAt, { format: 'DD/MM/YYYY HH:mm' }) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Partner ID') }}
                </div>
                <div>{{ partner.id }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Phonetic Name') }}
                </div>
                <div>{{ partner.phoneticName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Relationship') }}
                </div>
                <div>{{ partner.relationship === 'PO' ? 'Property Owner' : 'Vendor' }}</div>
              </div>
            </div>
            <div v-if="partner.partnerName" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Name') }}
                </div>
                <div>{{ partner.partnerName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Business') }}
                </div>
                <div>{{ partner.business }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Individual / Corporate') }}
                </div>
                <div>{{ partner.businessType }}</div>
              </div>
            </div>
            <div v-if="partner.properties.length" class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Name') }}
                </div>
                <div>{{ partner.properties.map((p) => p.name).join(',') }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Memo') }}
                </div>
                <div>{{ partner.memo }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
            {{ $t('Permission') }}
          </h2>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Partner status') }}
              </div>
            </div>
            <div class="column is-narrow">
              <a-switch v-model:checked="status" :loading="submitting" />
            </div>
          </div>
          <hr>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-danger has-text-weight-bold">
                {{ $t('Delete partner') }}
              </div>
              <div>{{ $t('del-desc') }}</div>
            </div>
            <div class="column is-narrow">
              <Delete
                action-handler="partners/delete"
                :payload-value="{ id: partner.id }"
                :title="$t('title')"
                :description="$t('desc', { name: partner.name })"
                :success-title="$t('deleted-title')"
                :success-description="$t('deleted-desc', { name: partner.name })"
                @close="$router.push({ path: '/partners' })"
              >
                <template #handler="{ show }">
                  <a-button type="danger" @click="show">
                    {{ $t('Delete') }}
                  </a-button>
                </template>
              </Delete>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/locales/components/prefectures.json"></i18n>
<!-- eslint-disable -->
<i18n>
{
"en": {
"Partner Details": "Partner Details",
"Partner info": "Partner info",
"Edit": "Edit",
"Partner Name": "Partner Name",
"Postal Code": "Postal Code",
"Country": "Country",
"Perfecture / State": "Prefecture / State",
"Address": "Address",
"Added at": "Added at",
"Partner ID": "Partner ID",
"Phonetic Name": "Phonetic Name",
"Relationship": "Relationship",
"Property Name": "Property Name",
"Business": "Business",
"Individual / Corporate": "Individual / Corporate",
"Memo": "Memo",
"Permission": "Permission",
"Partner status": "Partner status",
"Delete partner": "Delete partner",
"del-desc": "Once deleted, you cannot recover this partner. Please be certain.",
"Delete": "Delete",
"title": "Delete partner?",
"desc": "Are you use you want to delete <strong>{name}</strong> ?<br> Once deleted, you cannot recover this partner and its data.",
"deleted-title": "Partner is deleted",
"deleted-desc": "Partner <strong>{name}</strong> has been removed."
},
"ja": {
"Partner Details": "取引先詳細",
"Partner info": "取引先情報",
"Edit": "編集する",
"Partner Name": "取引先名称",
"Postal Code": "郵便番号",
"Country": "国名",
"Perfecture / State": "都道府県",
"Address": "住所",
"Added at": "作成日時",
"Partner ID": "取引先コード",
"Phonetic Name": "取引先よみ",
"Relationship": "関係性（業者なのか、お客様なのか）",
"Property Name": "物件正式名称",
"Business": "事業",
"Memo": "Memo",
"Individual / Corporate": "個人事業主・法人",
"Permission": "許可",
"Partner status": "取引先ステータス",
"Delete partner": "取引先を削除",
"del-desc": "一度削除するとこの取引先は復旧できません。必ず確認してください。",
"Delete": "削除",
"title": "取引先を削除?",
"desc": "本当にこの <strong>{name}</strong> を削除しますか？<br>一度削除するとこの取引先のデータは復旧できません",
"deleted-title": "取引先を削除",
"deleted-desc": "取引先<strong>{name}</strong>が削除されました"
}
}
</i18n>
<!-- eslint-enable -->

<script>
import { mapState } from 'vuex';
import IconEdit from '@/components/icons/IconEdit';
import Modal from '@/components/Modal';
import Delete from '@/components/PopupDelete';
import PartnerForm from '@/views/partners/components/PartnerForm';

export default {
  name: 'PartnerOverview',
  components: {
    PartnerForm,
    Delete,
    Modal,
    IconEdit,
  },
  data() {
    return {
      submitting: false,
      propertyList: [],
    };
  },
  computed: {
    ...mapState('partners', ['partner', 'loading']),
    status: {
      get() {
        return this.partner.isActive;
      },
      set(val) {
        this.$store.commit('partners/SET_PARTNER', { ...this.partner, isActive: val });
        this.submitting = true;
        this.$store
          .dispatch('partners/update', {
            ...this.partner,
          })
          .then(() => {
            this.submitting = false;
          })
          .catch(() => {
            this.$store.commit('partners/SET_PARTNER', { ...this.partner, isActive: !val });
            this.submitting = false;
          });
      },
    },
  },
  created() {
    this.getPropertyList();
  },
  methods: {
    getPropertyList() {
      this.$store.dispatch('properties/list', { infinite: true }).then((res) => {
        this.propertyList = res.list;
      });
    },
    asyncData() {
      this.$store.dispatch('partners/get', { id: this.$route.params.id });
    },
  },
};
</script>

<style scoped></style>
