<template>
  <div class="section">
    <div v-if="partner" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Person in Charge (PIC)') }}
          </h2>
        </div>
        <div v-permission="['add-partner']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <PicForm
                :value="partner"
                :partner="partner"
                @close="
                  () => {
                    hide();
                    fetchData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add NEW PIC') }}</a
              >
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        row-key="id"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #reportedTime="{ record }">
          {{ $filters.date(record.reportedTime) }}
        </template>
        <template #name="{ record }">
          {{ record.name }}
        </template>
        <template #email="{ record }">
          {{ record.email }}
        </template>

        <template #status="{ record }">
          <div @click.stop>
            <a-switch
              v-model:checked="record.isActive"
              :disabled="!canEdit"
              @change="(status) => { updateStatus(status, record); }"
            />
          </div>
        </template>
        <template #action="{ record }">
          <Delete
            action-handler="pic/delete"
            :payload-value="{ id: record.id, partnerId: record.partnerId }"
            :handler-v-permission="['modify-partner']"
            :title="$t('title')"
            :description="$t('desc')"
            :success-title="$t('deleted-title')"
            :success-description="$t('deleted-desc')"
            @close="fetchData"
          />
        </template>
      </a-table>
      <Modal ref="editModel">
        <template #default="{ hide }">
          <PicForm
            :value="partner"
            :pic="selectPic"
            @close="
              () => {
                hide();
                fetchData();
              }
            "
            @cancel="hide"
          />
        </template>
      </Modal>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Person in Charge (PIC)": "Person in Charge (PIC)",
"Add NEW PIC": "Add NEW PIC",
"PIC Name": "PIC Name",
"Email": "Email",
"Telephone": "Telephone",
"Fax": "Fax",
"Status": "Status",
"title": "Delete PIC",
"desc": "Are you sure delete this PIC?",
"deleted-title": "PIC is deleted",
"deleted-desc": "PIC has been removed."
},
"ja": {
"Person in Charge (PIC)": "主担当者",
"Add NEW PIC": "新規主担当者を追加する",
"PIC Name": "主担当者",
"Email": "メールアドレス",
"Telephone": "電話番号",
"Fax": "ファックス",
"Status": "ステータス",
"title": "主担当者を削除",
"desc": "A本当に主担当者を削除しますか？",
"deleted-title": "主担当者を削除",
"deleted-desc": "主担当者が削除されました"
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import PicForm from '@/views/partners/components/PicForm';
import checkPermission from '@/utils/permission';
import Delete from '@/components/PopupDelete';

export default {
  name: 'PartnerPic',
  components: {
    PicForm,
    Modal,
    Delete,
  },
  data() {
    return {
      submitting: false,
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('PIC Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Email'),
          dataIndex: 'email',
          key: 'email',
          slots: { customRender: 'email' },
        },
        {
          title: this.$t('Telephone'),
          dataIndex: 'phone',
          key: 'phone',
          slots: { customRender: 'phone' },
        },
        {
          title: this.$t('Fax'),
          key: 'fax',
          dataIndex: 'fax',
          slots: { customRender: 'fax' },
        },
        {
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
      selectPic: null,
    };
  },
  computed: {
    ...mapState('partners', ['partner', 'loading']),
    canEdit() {
      return checkPermission(['modify-partner']);
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'partner-pic') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('pic/list', { partnerId: this.partner.id })
        .then((resp) => {
          this.list = resp.list.map((p, index) => {
            p._id = index + 1;
            return p;
          });
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    updateStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      // eslint-disable-next-line no-unused-vars
      const { _id, ...rest } = record;
      this.$store.dispatch('pic/update', { id: record.id, isActive: status }).finally(() => {
        this.fetchData();
      });
    },
    // eslint-disable-next-line no-unused-vars
    rowClicked(record) {
      return {
        onClick: () => {
          this.selectPic = record;
          this.$refs.editModel.show();
        },
      };
    },
  },
};
</script>

<style scoped></style>
