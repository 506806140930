<template>
  <div class="section">
    <div v-if="partner" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <h2 class="has-text-primary has-text-weight-bold is-size-4">
            {{ $t('Bank Account') }}
          </h2>
        </div>
        <div v-permission="['add-partner']" class="column is-narrow">
          <Modal>
            <template #default="{ hide }">
              <BankForm
                :value="partner"
                @close="
                  () => {
                    hide();
                    fetchData();
                  }
                "
                @cancel="hide"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent="show">
                {{ $t('Add NEW BANK') }}</a
              >
            </template>
          </Modal>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="list"
        :loading="isFetching"
        :custom-row="rowClicked"
        row-key="id"
      >
        <template #id="{ text }">
          {{ text }}
        </template>
        <template #name="{ record }">
          {{ record.bankName }}
        </template>
        <template #accountName="{ record }">
          {{ record.accountName }}
        </template>
        <template #remarks="{ record }">
          {{ record.remarks }}
        </template>

        <template #status="{ record }">
          <div @click.stop>
            <a-switch
              v-model:checked="record.isActive"
              :disabled="!canEdit"
              @change="(status) => { updateStatus(status, record); }"
            />
          </div>
        </template>
        <template #action="{ record }">
          <Delete
            action-handler="bank/delete"
            :payload-value="{ id: record.id, partnerId: record.partnerId }"
            :handler-v-permission="['modify-partner']"
            :title="$t('title')"
            :description="$t('desc')"
            :success-title="$t('deleted-title')"
            :success-description="$t('deleted-desc')"
            @close="fetchData"
          />
        </template>
      </a-table>
      <Modal ref="editModel">
        <template #default="{ hide }">
          <BankForm
            :value="partner"
            :bank="selectBank"
            @close="
              () => {
                hide();
                fetchData();
              }
            "
            @cancel="hide"
          />
        </template>
      </Modal>
    </div>
  </div>
</template>

<i18n>
{
"en":{
"Bank Account": "Bank Account",
"Add NEW BANK": "Add NEW BANK",
"Bank Name": "Bank Name",
"Account Name": "Account Name",
"Remark": "Remark",
"Status": "Status",
"title": "Delete Bank Account",
"desc": "Are you sure delete this Bank Account?",
"deleted-title": "Bank Account is deleted",
"deleted-desc": "Bank Account has been removed."
},
"ja":{
"Bank Account": "銀行口座",
"Add NEW BANK": "銀行を追加",
"Bank Name": "銀行名",
"Account Name": "口座名(漢字)",
"Remark": "備考",
"Status": "ステータス",
"title": "銀行口座を削除",
"desc": "A本当に銀行口座を削除しますか？",
"deleted-title": "銀行口座を削除",
"deleted-desc": "銀行口座が削除されました"
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import BankForm from '@/views/partners/components/BankForm';
import checkPermission from '@/utils/permission';
import Delete from '@/components/PopupDelete';

export default {
  name: 'PartnerBank',
  components: {
    BankForm,
    Modal,
    Delete,
  },
  data() {
    return {
      submitting: false,
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Bank Name'),
          dataIndex: 'bankName',
          slots: { customRender: 'bankName' },
        },
        {
          title: this.$t('Account Name'),
          dataIndex: 'accountName',
          key: 'accountName',
          slots: { customRender: 'accountName' },
        },
        {
          title: this.$t('Remark'),
          key: 'remarks',
          dataIndex: 'remarks',
          slots: { customRender: 'remarks' },
        },
        {
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
      selectBank: null,
    };
  },
  computed: {
    ...mapState('partners', ['partner', 'loading']),
    canEdit() {
      return checkPermission(['modify-partner']);
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'partner-bank') {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('bank/list', { partnerId: this.partner.id })
        .then((resp) => {
          this.list = resp.list.map((p, index) => {
            p._id = index + 1;
            return p;
          });
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    updateStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      // eslint-disable-next-line no-unused-vars
      const { _id, ...rest } = record;
      this.$store
        .dispatch('bank/update', { id: record.id, isActive: status, ...rest })
        .finally(() => {
          this.fetchData();
        });
    },
    // eslint-disable-next-line no-unused-vars
    rowClicked(record) {
      return {
        onClick: () => {
          this.selectBank = record;
          this.$refs.editModel.show();
        },
      };
    },
  },
};
</script>

<style scoped></style>
