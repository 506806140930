<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ pic ? $t('Edit Person in Charge') : $t('Add New Person in Charge') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Contact Person Name')" name="name">
      <a-input v-model:value="form.name" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Email')" name="email">
      <a-input v-model:value="form.email" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Telephone')" name="telephone">
      <a-input v-model:value="form.phone" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Fax')" name="fax">
      <a-input v-model:value="form.fax" :size="size" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Edit Person in Charge": "Edit Person in Charge",
"Add New Person in Charge": "Add New Person in Charge",
"Contact Person Name": "Contact Person Name",
"Email": "Email",
"Telephone": "Telephone",
"Fax": "Fax",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Edit Person in Charge": "主担当者を編集",
"Add New Person in Charge": "新規主担当者を追加する",
"Contact Person Name": "連絡担当者",
"Email": "メールアドレス",
"Telephone": "電話番号",
"Fax": "ファックス",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
export default {
  name: 'PicForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    pic: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        name: undefined,
        email: undefined,
        phone: undefined,
        fax: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    pic: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.form.name = nv.name;
          this.form.email = nv.email;
          this.form.phone = nv.phone;
          this.form.fax = nv.fax;
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        if (this.pic && this.pic.id) {
          await this.$store.dispatch('pic/update', {
            id: this.pic.id,
            partnerId: this.value.id,
            ...this.form,
          });
        } else {
          await this.$store.dispatch('pic/add', { partnerId: this.value.id, ...this.form });
        }
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
