<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ bank ? $t('Edit Bank Account') : $t('Add New Bank Account') }}
      </h3>
    </div>
    <a-form-item class="column is-6" :label="$t('Bank Name')" name="bankName">
      <a-input v-model:value="form.bankName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Bank Code')" name="bankCode">
      <a-input v-model:value="form.bankCode" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Branch Name')" name="branchName">
      <a-input v-model:value="form.branchName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Branch Number')" name="branchNumber">
      <a-input v-model:value="form.branchNumber" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Contact Number')" name="contactNumber">
      <a-input v-model:value="form.contactNumber" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Remarks')" name="remarks">
      <a-input v-model:value="form.remarks" :size="size" />
    </a-form-item>
    <!--
      <a-form-item
      class="column is-12"
      label="Partner ID"
      name="partnerID"
      >
      <a-input
      v-model:value="form.partnerID"
      :size="size"
      />
      </a-form-item>
    -->
    <a-form-item class="column is-12" :label="$t('Payment / Transfer')" name="accountFor">
      <a-select v-model:value="form.accountFor" :size="size">
        <a-select-option value="Payment">
          {{ $t('Payment') }}
        </a-select-option>
        <a-select-option value="Transfer">
          {{ $t('Transfer') }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Account Type')" name="accountType">
      <a-input v-model:value="form.accountType" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Account Number')" name="accountNumber">
      <a-input v-model:value="form.accountNumber" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Account Name')" name="accountName">
      <a-input v-model:value="form.accountName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Account Name (Kana)')" name="accountNameKana">
      <a-input v-model:value="form.accountNameKana" :size="size" />
    </a-form-item>
    <!--
      <a-form-item
      class="column is-12"
      label="Property Name"
      name="propertyName"
      >
      <a-select
      v-model:value="form.propertyName"
      :size="size"
      >
      <a-select-option value="option">
      option
      </a-select-option>
      </a-select>
      </a-form-item>
    -->

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Edit Bank Account": "Edit Bank Account",
    "Add New Bank Account": "Add New Bank Account",
    "Bank Name": "Bank Name",
    "Bank Code": "Bank Code",
    "Branch Name": "Branch Name",
    "Branch Number": "Branch Number",
    "Contact Number": "Contact Number",
    "Remarks": "Remarks",
    "Payment / Transfer": "Payment / Transfer",
    "Payment": "Payment",
    "Transfer": "Transfer",
    "Account Type": "Account Type",
    "Account Number": "Account Number",
    "Account Name": "Account Name",
    "Account Name (Kana)": "Account Name (Kana)",
    "Cancel": "Cancel",
    "Save": "Save",
    "Bank name is required" : "Bank name is required",
    "Bank code is required" : "Bank code is required",
    "Branch name is required" : "Branch name is required",
    "Payment/transfer is required" : "Payment/transfer is required",
    "Account type is required" : "Account type is required",
    "Account number is required" : "Account number is required",
    "Account name is required" : "Account name is required",
    "Account name (kana) is required": "Account name (kana) is required"
  },
  "ja": {
    "Edit Bank Account": "銀行口座を編集",
    "Add New Bank Account": "口座を追加",
    "Bank Name": "銀行名",
    "Bank Code": "銀行コード",
    "Branch Name": "支店名",
    "Branch Number": "支店番号",
    "Contact Number": "連絡先",
    "Remarks": "備考",
    "Payment / Transfer": "入金口座／振込口座",
    "Payment": "入金口座",
    "Transfer": "振込口座",
    "Account Type": "口座種別",
    "Account Number": "口座番号",
    "Account Name": "口座名(漢字)",
    "Account Name (Kana)": "口座名(カタカナ)",
    "Cancel": "取り消し",
    "Save": "保存",
    "Bank name is required" : "銀行名は必須です",
    "Bank code is required" : "銀行コードは必須です",
    "Branch name is required" : "支店名は必須です",
    "Payment/transfer is required" : "入金口座／振込口座は必須です",
    "Account type is required" : "口座種別は必須です",
    "Account number is required" : "口座番号は必須です",
    "Account name is required" : "口座名(漢字)は必須です",
    "Account name (kana) is required": "口座名(カタカナ)は必須です"
  }
}
</i18n>

<script>
export default {
  name: 'BankForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    bank: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        bankName: undefined,
        bankCode: undefined,
        branchName: undefined,
        branchNumber: undefined,
        contactNumber: undefined,
        accountFor: undefined,
        accountType: undefined,
        accountNumber: undefined,
        accountName: undefined,
        accountNameKana: undefined,
        remarks: undefined,
      },
      rules: {
        bankName: [
          {
            required: true,
            message: this.$t('Bank name is required'),
            trigger: ['change', 'blur'],
          },
        ],
        bankCode: [
          {
            required: true,
            message: this.$t('Bank code is required'),
            trigger: ['change', 'blur'],
          },
        ],
        branchName: [
          {
            required: true,
            message: this.$t('Branch name is required'),
            trigger: ['change', 'blur'],
          },
        ],
        accountFor: [
          {
            required: true,
            message: this.$t('Payment/transfer is required'),
            trigger: ['change', 'blur'],
          },
        ],
        accountType: [
          {
            required: true,
            message: this.$t('Account type is required'),
            trigger: ['change', 'blur'],
          },
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('Account number is required'),
            trigger: ['change', 'blur'],
          },
        ],
        accountName: [
          {
            required: true,
            message: this.$t('Account name is required'),
            trigger: ['change', 'blur'],
          },
        ],
        accountNameKana: [
          {
            required: true,
            message: this.$t('Account name (kana) is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    bank: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            this.form[k] = nv[k];
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        if (this.bank) {
          await this.$store.dispatch('bank/update', {
            id: this.bank.id,
            partnerId: this.bank.partnerId,
            ...this.form,
          });
        } else {
          await this.$store.dispatch('bank/add', { partnerId: this.value.id, ...this.form });
        }
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
